import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import convertBold from '../../util/convertBold';

const StyledCopy = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	background-image: url(${(p) => p.img});
	background-position: ${(p) => p.position};
	background-size: ${(p) => p.size};
	background-repeat: no-repeat;
	.text-container {
		${(p) => p.margin}
		width: ${(p) => p.size};
		h2 {
			margin-bottom: 2rem;
		}
		p {
			margin-bottom: 3rem;
		}
	}
	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1500px) {
		padding: 6rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 8rem ${(p) => p.theme.gutterXWide};
	}
	@media all and (max-width: 900px) {
		padding: 3rem ${(p) => p.theme.gutter} 13rem;
		background-position: center bottom;
		background-size: 60% auto;
		.text-container {
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 2rem ${(p) => p.theme.gutterMobile} 13rem;
		background-size: 80% auto;
	}
`;
// 									    |***>	side refers to the background image
const Copy = ({ title, copy, cta, img, side }) => {
	let margin = `margin-${side}: auto;`;
	let position = side === 'right' ? 'right 101%' : 'left center';
	let size = side === 'right' ? '50%' : '48%';

	return (
		<StyledCopy img={img} margin={margin} position={position} size={size}>
			<div className="text-container">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(title),
					}}
				/>
				<p role="heading" aria-level={3}>{copy}</p>
				<Link to={cta.link} className="redButton">
					{cta.text}
				</Link>
			</div>
		</StyledCopy>
	);
};

export default Copy;
