import React from 'react';

import Masthead from '../components/masthead';
import Tiles from '../components/home/tiles';
import Copy from '../components/home/copy';
import Quad from '../components/home/quad';
import Form from '../components/home/form';

const HomeTemplate = ({ copy }) => {
	const { masthead, copy1, quad, tiles, copy2, form } = copy;
	return (
		<>
			<Masthead
				title={masthead.title}
				cta={masthead.cta}
				img={masthead.img}
				className="home"
			/>
			<Copy
				title={copy1.title}
				copy={copy1.copy}
				cta={copy1.cta}
				img={copy1.img}
				side="right"
			/>
			<Quad
				title={quad.title}
				copy1={quad.copy1}
				copy2={quad.copy2}
				copy3={quad.copy3}
				copy4={quad.copy4}
			/>
			<Tiles
				img1={tiles.img1}
				img2={tiles.img2}
				img3={tiles.img3}
				img4={tiles.img4}
			/>
			<Copy
				title={copy2.title}
				copy={copy2.copy}
				cta={copy2.cta}
				img={copy2.img}
				side="left"
			/>
			<Form
				title={form.title}
				address={form.address}
				phone={form.phone}
				tollFree={form.tollFree}
				img={form.img}
			/>
		</>
	);
};

export default HomeTemplate;
