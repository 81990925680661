import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledTiles = styled.div`
	.tile-row {
		display: flex;
		a {
			background-position: center;
			background-size: 100.5% auto;
			background-repeat: no-repeat;
			width: 50%;
			height: 20rem;
			text-align: center;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			transition: 0.5s ease-out;
			&:hover {
				background-size: 110% auto;
				transition: 0.5s ease-out;
			}
			h2 {
				font-weight: 700;
				font-size: 2rem;
				color: #fff;
				margin-bottom: 1rem;
				padding: 0 1rem;
			}
		}
	}
	@media all and (min-width: 1200px) {
		.tile-row a {
			height: 30rem;
			h2 {
				margin-bottom: 2rem;
			}
		}
	}
	@media all and (min-width: 1600px) {
		.tile-row a h2 {
			font-size: 3.5rem;
			margin-bottom: 4rem;
		}
	}
	@media all and (max-width: 768px) {
		.tile-row a {
			background-size: cover;
			&:hover {
				background-size: cover;
			}
		}
	}
	@media all and (max-width: 588px) {
		padding: 2rem ${(p) => p.theme.gutter};
		margin: 0 auto;
		.tile-row {
			flex-wrap: wrap;
			a {
				width: 100%;
				height: 25rem;
				margin-top: 2rem;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: 1rem ${(p) => p.theme.gutterMobile};
	}
	@media all and (max-width: 450px) {
		.tile-row a {
			height: 20rem;
		}
	}
`;

const Tiles = ({ img1, img2, img3, img4 }) => {
	return (
		<StyledTiles id="tiles">
			<div className="tile-row">
				<Link to="/freight/" style={{ backgroundImage: `url(${img1})` }}>
					<h2>FREIGHT</h2>
				</Link>
				<Link
					to="/freight-forwarding/"
					style={{ backgroundImage: `url(${img2})` }}
				>
					<h2>FREIGHT FORWARDING</h2>
				</Link>
			</div>
			<div className="tile-row">
				<Link to="/warehousing/" style={{ backgroundImage: `url(${img3})` }}>
					<h2>WAREHOUSING</h2>
				</Link>
				<Link to="/customs/" style={{ backgroundImage: `url(${img4})` }}>
					<h2>CUSTOMS</h2>
				</Link>
			</div>
		</StyledTiles>
	);
};

export default Tiles;
