import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledHomeForm = styled.div`
	padding: 5rem 7rem;
	background-image: url(${(p) => p.img});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	.links {
		width: 50%;
		h2 {
			color: #fff;
			margin-bottom: 1rem;
		}
		a {
			color: #fff;
			transition: 0.5s;
			position: relative;
			&::after {
				content: '';
				background-color: #fff;
				transition: 0.5s;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				opacity: 0;
			}
			&:hover::after {
				opacity: 1;
			}
		}
		.phone a {
			margin-left: 5px;
		}
		p {
			color: #fff;
		}
	}
	.form-container {
		width: 50%;
		label {
			display: block;
			color: #fff;
			margin: 15px 0 5px;
			&::after {
				content: '*:';
			}
			&.phone::after {
				content: '';
			}
		}
		input {
			width: 80%;
			padding: 5px;
			height: 2rem;
		}
		textarea {
			width: 80%;
			resize: none;
			padding: 5px;
			height: 5rem;
			margin-bottom: 1rem;
		}
		button {
			padding: 0.5rem 2.5rem;
			cursor: pointer;
		}
		.thanks {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: #fff;
			h2 {
				color: #fff;
			}
		}
	}
	@media all and (min-width: 1200px) {
		padding: 5rem 12rem;
	}
	@media all and (min-width: 1600px) {
		.form-container {
			label {
				font-size: 1.3rem;
				margin: 20px 0 10px;
			}
			button {
				border-radius: 30px;
			}
			label {
				font-size: 1.2rem;
				margin: 20px 0 10px;
			}
		}
	}
	@media all and (max-width: 1050px) {
		padding: 5rem ${(p) => p.theme.gutter};
	}
	@media all and (max-width: 868px) {
		h2,
		.address {
			width: 180px;
		}
	}
	@media all and (max-width: 678px) {
		flex-wrap: wrap;
		h2,
		.address {
			width: auto;
		}
		.links,
		.form-container {
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem;
		.form-container {
			input,
			textarea {
				width: 330px;
			}
		}
	}
	@media all and (max-width: 450px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
		.form-container {
			input,
			textarea {
				width: 90%;
			}
		}
	}
	@media all and (max-width: 410px) {
		h2,
		.address {
			width: 180px;
		}
	}
`;

const HomeForm = ({ title, address, phone, tollFree, img }) => {
	const [thanks, setThanks] = useState(false);
	const [height, setHeight] = useState(371);

	useEffect(() => {
		setThanks(window.location.search === '?thanks=true');
		const handleResize = () => {
			if (window.innerWidth > 1600) setHeight(431);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledHomeForm img={img} id="form">
			<div className="links">
				<h2>{title}</h2>
				<p className="address">
					<a href="https://goo.gl/maps/14eC3Purew6QxU1w6">{address}</a>
				</p>
				<p className="phone">
					Telephone:
					<a href={`tel:${phone.raw}`}>{phone.format}</a>
				</p>
				<p className="phone">
					Toll Free:
					<a href={`tel:${tollFree.raw}`}>{tollFree.format}</a>
				</p>
			</div>
			<div className="form-container">
				{thanks && (
					<div
						className="thanks"
						id="mobileThanksHeight"
						style={{ height: `${height}px` }}
					>
						<h2>Thanks for submitting.</h2>
						<p>You will hear from us shortly.</p>
					</div>
				)}

				{!thanks && (
					<form
						method="post"
						name="Home Form"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						action="/?thanks=true#form"
					>
						<input type="hidden" name="form-name" value="Home Form" />
						<input type="hidden" name="bot-field" />
						<div className="field">
							<label htmlFor="name">Name</label>
							<input type="text" id="name" name="name" required aria-required="true"/>
						</div>
						<div className="field">
							<label htmlFor="email">Email</label>
							<input type="email" id="email" name="email" required aria-required="true"/>
						</div>
						<div className="field">
							<label htmlFor="phone" className="phone">
								Phone:
							</label>
							<input type="text" id="phone" name="phone" />
						</div>
						<div className="field">
							<label htmlFor="message">Message</label>
							<textarea type="text" id="message" name="message" required aria-required="true"/>
						</div>
						<button className="redButton" type="submit">
							SUBMIT
						</button>
					</form>
				)}
			</div>
		</StyledHomeForm>
	);
};

export default HomeForm;
