import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import HomeTemplate from '../templates/index-template';
import SEO from '../components/seo';

const IndexPage = ({ data }) => {
	const content = data.pagesJson;

	return (
		<Layout popup={true}>
			<SEO title="Home" />
			<HomeTemplate copy={content} />
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query HomePageQuery {
		pagesJson(page: { eq: "index" }) {
			masthead {
				title
				cta {
					text
					link
				}
				img
			}
			copy1 {
				title
				copy
				cta {
					link
					text
				}
				img
			}
			quad {
				title
				copy1 {
					copy
					title
				}
				copy2 {
					copy
					title
				}
				copy3 {
					copy
					title
				}
				copy4 {
					copy
					title
				}
			}
			tiles {
				img1
				img2
				img3
				img4
			}
			copy2 {
				title
				copy
				cta {
					link
					text
				}
				img
			}
			form {
				title
				address
				phone {
					format
					raw
				}
				tollFree {
					format
					raw
				}
				img
			}
		}
	}
`;
