import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import convertBold from '../../util/convertBold';

const StyledQuad = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	background-color: ${(p) => p.theme.color.purple};
	color: #fff;
	h2 {
		color: #fff;
		text-align: center;
		margin-bottom: 2rem;
		strong {
			color: #fff;
		}
	}
	h3 {
		color: #fff;
		margin-bottom: 1rem;
		font-size: 1.4rem;
		line-height: 1.7rem;
	}
	.copy-row {
		display: flex;
	}
	.copy-box {
		width: 50%;
		&.topLeft {
			border-bottom: 2px solid #26135f;
			border-right: 2px solid #26135f;
			padding: 0 3rem 3rem 0;
		}
		&.topRight {
			padding: 0 0 3rem 3rem;
			border-bottom: 2px solid #26135f;
			border-left: 2px solid #26135f;
		}
		&.bottomLeft {
			padding: 3rem 3rem 0 0;
			border-top: 2px solid #26135f;
			border-right: 2px solid #26135f;
		}
		&.bottomRight {
			border-top: 2px solid #26135f;
			border-left: 2px solid #26135f;
			padding: 3rem 0 0 3rem;
		}
	}

	.next,
	.prev {
		position: relative;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			width: 15px;
			height: 15px;
			display: block;
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
		}
		&.end::after {
			opacity: 0;
		}
	}
	.prev::after {
		right: 10px;
		transform: rotate(45deg);
	}
	.next::after {
		transform: rotate(225deg);
		left: 10px;
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
	}
	@media all and (max-width: 768px) {
		padding: 4rem ${(p) => p.theme.gutter} 2rem;
		.slider-control-topcenter {
			width: 100%;
			height: 4px;
			background-color: #8c85a4;
			top: 5px !important;
			.indicator {
				width: 25%;
				height: 4px;
				background-color: #fff;
				position: absolute;
				transition: 0.5s;
				&.slide-0 {
					left: 0;
				}
				&.slide-1 {
					left: 25%;
				}
				&.slide-2 {
					left: 50%;
				}
				&.slide-3 {
					left: 75%;
				}
			}
		}
		.copy-box {
			width: 100%;
			border: none !important;
			padding: 2rem 0;
			h3 {
				margin-top: 1rem;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: ${(p) => p.theme.gutterMobile};
		.slider-control-topcenter {
			width: calc(100% - 20px);
		}
		.prev::after {
			right: -10px;
			transform: rotate(45deg);
		}
		.next::after {
			transform: rotate(225deg);
			left: -10px;
		}
	}
`;

const Quad = ({ title, copy1, copy2, copy3, copy4 }) => {
	const [width, setWidth] = useState();
	const [slide, setSlide] = useState(0);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledQuad>
			<h2
				dangerouslySetInnerHTML={{
					__html: convertBold(title),
				}}
			/>
			{width > 768 && (
				<>
					<div className="copy-row">
						<div className="copy-box topLeft">
							<h3>{copy1.title}</h3>
							<p role="heading" aria-level={4}>{copy1.copy}</p>
						</div>
						<div className="copy-box topRight">
							<h3>{copy2.title}</h3>
							<p role="heading" aria-level={4}>{copy2.copy}</p>
						</div>
					</div>
					<div className="copy-row">
						<div className="copy-box bottomLeft">
							<h3>{copy3.title}</h3>
							<p role="heading" aria-level={4}>{copy3.copy}</p>
						</div>
						<div className="copy-box bottomRight">
							<h3>{copy4.title}</h3>
							<p role="heading" aria-level={4}>{copy4.copy}</p>
						</div>
					</div>
				</>
			)}
			{width <= 768 && (
				<Carousel
					afterSlide={(slideIndex) => setSlide(slideIndex)}
					renderTopLeftControls={({ previousSlide }) => (
						<span
							className={slide === 0 ? 'prev end' : 'prev'}
							onClick={previousSlide}
						/>
					)}
					renderTopRightControls={({ nextSlide }) => (
						<span
							className={slide === 3 ? 'next end' : 'next'}
							onClick={nextSlide}
						/>
					)}
					renderTopCenterControls={({ currentSlide }) => (
						<div className={`indicator slide-${currentSlide}`} />
					)}
					renderBottomCenterControls={null}
					renderCenterRightControls={null}
					renderCenterLeftControls={null}
				>
					<div className="copy-box ">
						<h3>{copy1.title}</h3>
						<p>{copy1.copy}</p>
					</div>
					<div className="copy-box ">
						<h3>{copy2.title}</h3>
						<p>{copy2.copy}</p>
					</div>
					<div className="copy-box">
						<h3>{copy3.title}</h3>
						<p>{copy3.copy}</p>
					</div>
					<div className="copy-box ">
						<h3>{copy4.title}</h3>
						<p>{copy4.copy}</p>
					</div>
				</Carousel>
			)}
		</StyledQuad>
	);
};

export default Quad;
